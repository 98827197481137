export const embeddedAnswerProviderOptions = [
    {
        value: 'OPENAI',
        label: 'Open AI',
    },
    {
        value: 'GEMINI',
        label: 'Gemini',
    },
]

export const embeddedAnswerOpenAIModelOptions = [
    {
        value: 'gpt-4o-mini',
        label: 'GPT-4o Mini',
    },
    {
        value: 'gpt-4o',
        label: 'GPT-4o',
    },
]

export const embeddedAnswerGeminiModelOptions = [
    {
        value: 'gemini-1.5-flash',
        label: 'Gemini 1.5 Flash',
    },
]