import { ColorModeContext, useMode } from './theme'
import {Alert, AlertTitle, CssBaseline, ThemeProvider} from '@mui/material'
import {Routes, Route, useLocation} from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './scenes/dashboard'
import Login from './scenes/login'
import { QueryClient, QueryClientProvider } from 'react-query'
import {AuthScope, useAuth} from './actions/auth'
import { Provider } from 'react-redux'
import store from './redux/store'
import { loadCurrentConfigurations } from './actions/configuration'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import React from 'react'
import CrawlerConfigurationDetail from "./scenes/admin/crawler/detail";
import CrawlerConfiguration from "./scenes/admin/crawler";
import CrawlerRegexExtractor from "./scenes/admin/crawler/regex/extractor";
import CrawlerRegexExtractorDetail from "./scenes/admin/crawler/regex/extractor/detail";
import AuthTransitRoute from "./scenes/transit/route";
import CrawlerPublicDatasetConfiguration from "./scenes/admin/crawler/public/dataset";
import CrawlerPublicDatasetVectorSearch from "./scenes/crawler/vector";

function App() {
    const location = useLocation();
    const path = location.pathname;
    const [theme, colorMode] = useMode()
    const [isAuthenticated] = useAuth()
    const queryClient = new QueryClient()

    /**
     * Load current configurations upon startup
     */
    store.dispatch(loadCurrentConfigurations())

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <div className="app">
                                {path !== "/transit" && !isAuthenticated && <Login />}
                                {path !== "/transit" &&
                                    ((store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("CRAWLER")))
                                    && isAuthenticated && <Sidebar />}

                                <main className="content">
                                    {isAuthenticated && (
                                        <>
                                            <Topbar />

                                            {((store.getState().user?.user?.user?.scopes && !Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("CRAWLER"))) && (
                                                <Alert severity="error">
                                                    <AlertTitle>Access Denied: Insufficient Permissions</AlertTitle>
                                                    <p>We're sorry, but it seems that you don't have the necessary permissions to manage the crawler administration console in the user interface.</p>
                                                    <p>Access to the crawler administration console is restricted to authorized personnel only, as it involves critical settings and configurations for the system.</p>
                                                    <p>If you believe this is an error or you require access for specific tasks, please reach out to your system administrator or the designated support team. They will be able to assist you in obtaining the appropriate permissions or guide you through the necessary steps to achieve your desired objectives.</p>
                                                </Alert>
                                            )}

                                            {((store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("CRAWLER"))) && (
                                                <>
                                                    <Routes>
                                                        <Route
                                                            path="/"
                                                            element={<Dashboard />}
                                                        />

                                                        {store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'USER' && store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("CRAWLER") && (
                                                            <>
                                                                <Route
                                                                    path="/crawler/public/dataset/rag"
                                                                    element={
                                                                        <CrawlerPublicDatasetVectorSearch />
                                                                    }
                                                                />
                                                            </>
                                                        )}

                                                        {store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'ADMIN' && (
                                                            <>
                                                                <Route
                                                                    path="/crawler/regex/extractor"
                                                                    element={
                                                                        <CrawlerRegexExtractor />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/crawler"
                                                                    element={
                                                                        <CrawlerConfiguration />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/crawler/regex/extractor/create"
                                                                    element={
                                                                        <CrawlerRegexExtractorDetail isNew={true} />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/crawler/create"
                                                                    element={
                                                                        <CrawlerConfigurationDetail isNew={true} />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/crawler/public/dataset"
                                                                    element={
                                                                        <CrawlerPublicDatasetConfiguration />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/crawler/public/dataset/rag"
                                                                    element={
                                                                        <CrawlerPublicDatasetVectorSearch />
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Routes>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <Routes>
                                        <Route path="/transit" element={<AuthTransitRoute />} />
                                    </Routes>
                                </main>
                            </div>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </QueryClientProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
